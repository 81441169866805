/* eslint-disable @typescript-eslint/naming-convention */
export interface ExtraLinkProps {
    href: string;
    title: string;
}

const translations: { [key: string]: ExtraLinkProps[] } = {
    en: [{ href: '/en', title: 'Newsitems' }],
    fr: [{ href: '/fr', title: 'Informations' }],
    fr_BE: [{ href: '/fr-be', title: 'Informations' }],
    nl_BE: [{ href: '/nl-be', title: 'Nieuwsberichten' }],
    nl_NL: [{ href: '/', title: 'Nieuwsberichten' }],
    de: [{ href: '/de', title: 'Nachrichten' }],
};
export { translations };
