import { useCurrentLocale } from '@prezly/theme-kit-nextjs';

import { useFooterSeoInformationLinks } from '../libs/hooks';

import styles from './FooterSeo.module.scss';

function FooterSeo() {
    const currentLocale = useCurrentLocale();
    const list = useFooterSeoInformationLinks(currentLocale);

    return (
        <div className={styles.container}>
            {list?.length &&
                list.map(({ title, links }, index) => (
                    <div className={styles.column} key={index}>
                        <h3>{title}</h3>
                        <ul className={styles.list}>
                            {links.map((element, elementIndex) => (
                                <li key={elementIndex}>
                                    <a href={element.href} title={element.title}>
                                        {element.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
        </div>
    );
}

export default FooterSeo;
