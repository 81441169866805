import { getLanguageDisplayName, getUsedLanguages, LocaleObject } from '@prezly/theme-kit-core';
import {
    useCurrentLocale,
    useCurrentStory,
    useGetLinkLocaleSlug,
    useGetTranslationUrl,
    useLanguages,
} from '@prezly/theme-kit-nextjs';
import classNames from 'classnames';
import { useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { Dropdown } from '@/components';

import styles from './LanguagesDropdown.module.scss';

type Props = {
    buttonClassName?: string;
    navigationItemClassName?: string;
    hasError?: boolean;
};

function LocaleToSvg(locale: string) {
    const items = locale.split('_');

    if (items.length !== 2) {
        return items[0];
    }

    return items[1].toLowerCase();
}

function LanguagesDropdown({ buttonClassName, navigationItemClassName, hasError }: Props) {
    const currentLocale = useCurrentLocale();
    const languages = useLanguages();
    const getTranslationUrl = useGetTranslationUrl();
    const currentStory = useCurrentStory();
    const getLinkLocaleSlug = useGetLinkLocaleSlug();

    const currentLanguage = useMemo(
        () => languages.find((language) => language.code === currentLocale.toUnderscoreCode()),
        [currentLocale, languages],
    );

    const displayedLanguages = useMemo(() => {
        if (!languages.length) {
            return [];
        }

        return getUsedLanguages(languages).filter(
            (language) => language.code !== currentLocale.toUnderscoreCode(),
        );
    }, [currentLocale, languages]);

    // Don't show language selector if there are no other locale to choose
    if (!currentLanguage || displayedLanguages.length < 1) {
        return null;
    }

    return (
        <li className={classNames(navigationItemClassName)}>
            <Dropdown
                icon={() => (
                    <ReactCountryFlag
                        countryCode={LocaleToSvg(currentLanguage.locale.locale)}
                        className={styles.flag}
                        svg
                    />
                )}
                showCaret={false}
                className={styles.container}
                menuClassName={styles.menu}
                buttonClassName={classNames(buttonClassName, styles.button)}
                withMobileDisplay
                specialId="language_dropdown_id"
            >
                {/* current language */}
                <li>
                    <a href="#" className={classNames(styles.link, styles.active)}>
                        <ReactCountryFlag
                            countryCode={LocaleToSvg(currentLanguage.locale.locale)}
                            className={styles.flag}
                            svg
                        />
                        {getLanguageDisplayName(currentLanguage, languages)}
                    </a>
                </li>

                {displayedLanguages.map((language) => {
                    const locale = LocaleObject.fromAnyCode(language.code);
                    const translationLink = hasError ? '/' : getTranslationUrl(locale);

                    return (
                        <Dropdown.Item
                            key={locale.toHyphenCode()}
                            href={translationLink}
                            className={styles.dropdownItem}
                            linkClassName={styles.dropdownLink}
                            localeCode={
                                currentStory && translationLink !== '/'
                                    ? false
                                    : getLinkLocaleSlug(locale)
                            }
                            forceRefresh
                            withMobileDisplay
                        >
                            <ReactCountryFlag
                                countryCode={LocaleToSvg(language.locale.locale)}
                                className={styles.flag}
                                svg
                            />
                            {getLanguageDisplayName(language, languages)}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
            <ul className={styles.mobileWrapper}>
                {/* current language */}
                <li>
                    <a href="#" className={classNames(styles.link, styles.active)}>
                        <ReactCountryFlag
                            countryCode={LocaleToSvg(currentLanguage.locale.locale)}
                            className={styles.flag}
                            svg
                        />
                        {getLanguageDisplayName(currentLanguage, languages)}
                    </a>
                </li>

                {displayedLanguages.map((language) => {
                    const locale = LocaleObject.fromAnyCode(language.code);
                    const translationLink = hasError ? '/' : getTranslationUrl(locale);
                    const hrefWithLocale =
                        currentStory && translationLink !== '/'
                            ? translationLink
                            : `/${getLinkLocaleSlug(locale)}${translationLink}`;

                    return (
                        <li key={locale.toHyphenCode()}>
                            <a href={hrefWithLocale} className={styles.link}>
                                <ReactCountryFlag
                                    countryCode={LocaleToSvg(language.locale.locale)}
                                    className={styles.flag}
                                    svg
                                />
                                {getLanguageDisplayName(language, languages)}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </li>
    );
}

export default LanguagesDropdown;
