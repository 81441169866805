import { Font } from 'types';

export const FONT_FAMILY = {
    [Font.INTER]: 'Inter, sans-serif',
    [Font.MERRIWEATHER]: 'Merriweather, serif',
    [Font.OPEN_SANS]: "'Open Sans', sans-serif",
    [Font.PT_SERIF]: "'PT Serif', serif",
    [Font.ROBOTO]: 'Roboto, sans-serif',
    [Font.SOURCE_CODE_PRO]: "'Source Code Pro', monospace",
    [Font.QUICKSAND]: 'Quicksand, sans-serif',
    [Font.ICONFONT]: 'Iconfont, sans-serif',
};
