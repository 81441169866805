/* eslint-disable @typescript-eslint/naming-convention */

export interface TooltipProps {
    title: string;
    tooltip: string;
}

export interface SocialTranslationsProps<T> {
    ['social']: {
        [key: string]: T;
    };
}

const translations: SocialTranslationsProps<{ title: string }> = {
    social: {
        en: {
            title: 'Are you already fan? Follow us',
        },
        fr_FR: {
            title: 'Vous êtes déjà fan? Suivez-nous',
        },
        fr_BE: {
            title: 'Vous êtes déjà fan? Suivez-nous',
        },
        de: {
            title: 'Folge uns auf diesen Kanälen',
        },
        nl_NL: {
            title: 'Volg ons op deze kanalen',
        },
        nl_BE: {
            title: 'Volg ons op deze kanalen',
        },
    },
};

export default translations;
