import { Analytics, useAnalyticsContext } from '@prezly/analytics-nextjs';
import { PageSeo, useNewsroom, useNewsroomContext } from '@prezly/theme-kit-nextjs';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { Router } from 'next/router';
import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import { NotificationsBar } from '@/components';
import { useBlur } from '@/contexts/blur-context';
import { LoadingBar, ScrollToTopButton } from '@/ui';
import { EventEmitter } from '@/utils/events';

import Boilerplate from './Boilerplate';
import Branding from './Branding';
import Contacts from './Contacts';
import Header from './Header';

import styles from './Layout.module.scss';

interface Props {
    description?: string;
    imageUrl?: string;
    title?: string;
    hasError?: boolean;
}

const CookieConsentBar = dynamic(() => import('./CookieConsentBar'), {
    ssr: false,
});

function Layout({ children, description, imageUrl, title, hasError }: PropsWithChildren<Props>) {
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const newsroom = useNewsroom();
    const { contacts, notifications } = useNewsroomContext();
    const { isEnabled: isAnalyticsEnabled } = useAnalyticsContext();
    const { hasBlur } = useBlur();

    useEffect(() => {
        function onRouteChangeStart() {
            setIsLoadingPage(true);

            // when route changes, close all dropdowns
            EventEmitter.dispatch('closeAllDropdowns');
        }

        function routeChangeComplete() {
            setIsLoadingPage(false);
        }

        Router.events.on('routeChangeStart', onRouteChangeStart);
        Router.events.on('routeChangeComplete', routeChangeComplete);
        return () => {
            Router.events.off('routeChangeStart', onRouteChangeStart);
            Router.events.off('routeChangeComplete', routeChangeComplete);
        };
    }, []);

    return (
        <>
            <Analytics />
            <Branding newsroom={newsroom} />
            <PageSeo
                title={title}
                description={description}
                imageUrl={imageUrl}
                noindex={!isAnalyticsEnabled}
                nofollow={!isAnalyticsEnabled}
            />
            <NotificationsBar notifications={notifications} />
            <CookieConsentBar />
            <div className={styles.layout}>
                <Header hasError={hasError} />
                <div
                    className={classNames({
                        [styles.blur]: hasBlur,
                    })}
                >
                    <main className={styles.content}>
                        {children}
                        <LoadingBar isLoading={isLoadingPage} />
                    </main>
                    {contacts && <Contacts contacts={contacts} />}
                    <Boilerplate />
                </div>
            </div>
            <ScrollToTopButton />
        </>
    );
}

export default Layout;
