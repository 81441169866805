import tinycolor from 'tinycolor2';

import { Font } from 'types';

import { FONT_FAMILY } from './constants';

import styles from './Branding.module.scss';

const ACCENT_COLOR_TINT_FACTOR = 10;
const ACCENT_COLOR_SHADE_FACTOR = 10;

export function getCssVariables() {
    const accentColor = '#56aaae';
    const headerBackgroundColor = '#ffffff';
    const headerAccentColor = '#292929';

    // Use the default placeholder color if the header background color has not been changed
    const placeholderBackgroundColor = styles['default-placeholder-color'];

    const accentColorButtonText = styles['light-text-color'];

    return [
        `--prezly-font-family: ${FONT_FAMILY[Font.ROBOTO]}`,
        `--prezly-accent-color: ${accentColor}`,
        `--prezly-accent-color-tint: ${tinycolor(accentColor)
            .lighten(ACCENT_COLOR_TINT_FACTOR)
            .toHexString()}`,
        `--prezly-accent-color-shade: ${tinycolor(accentColor)
            .darken(ACCENT_COLOR_SHADE_FACTOR)
            .toHexString()}`,
        `--prezly-accent-color-button-text: ${accentColorButtonText}`,
        `--prezly-header-background-color: ${headerBackgroundColor}`,
        `--prezly-header-font-family: ${FONT_FAMILY[Font.QUICKSAND]}`,
        `--prezly-header-link-color: ${headerAccentColor}`,
        `--prezly-placeholder-background-color: ${placeholderBackgroundColor}`,
        `--prezly-icon-font-family: ${FONT_FAMILY[Font.ICONFONT]}`,
    ];
}

export function getGoogleFontName(font: Font): string {
    switch (font) {
        case Font.MERRIWEATHER:
            return 'Merriweather';
        case Font.OPEN_SANS:
            return 'Open+Sans';
        case Font.PT_SERIF:
            return 'PT+Serif';
        case Font.ROBOTO:
            return 'Roboto';
        case Font.SOURCE_CODE_PRO:
            return 'Source+Code+Pro';
        default:
            return 'Inter';
    }
}
