/* eslint-disable @typescript-eslint/naming-convention */
export interface PaymentsTranslationsProps {
    ['payments']: {
        [key: string]: { title: string };
    };
}

const translations: PaymentsTranslationsProps = {
    payments: {
        en: {
            title: 'Secure payment with',
        },
        de: {
            title: 'Sicher bezahlen mit',
        },
        fr_FR: {
            title: 'Paiement sécurisé avec',
        },
        fr_BE: {
            title: 'Paiement sécurisé avec',
        },
        nl_NL: {
            title: 'Veilig betalen met',
        },
        nl_BE: {
            title: 'Veilig betalen met',
        },
    },
};

export default translations;
