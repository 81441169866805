import type { Newsroom } from '@prezly/sdk';
import { getNewsroomFaviconUrl } from '@prezly/theme-kit-core';
import Head from 'next/head';

import { useThemeSettings } from '@/hooks';

import { getCssVariables } from './utils';

interface Props {
    newsroom: Newsroom;
}

// TODO: Figure out if we can load the stylesheets in `_document` to make Next happy
function Branding({ newsroom }: Props) {
    const themeSettings = useThemeSettings();
    const variables = getCssVariables();
    const faviconUrl = getNewsroomFaviconUrl(newsroom, 180);

    return (
        <Head>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
            <link
                href={`https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap`}
                rel="stylesheet"
            />
            <link
                href={`https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap`}
                rel="stylesheet"
            />
            {variables.length > 0 && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: `:root {${variables.map((variable) => variable).join(';')}}`,
                    }}
                />
            )}
            <link rel="apple-touch-icon" href="/apple-touch-icon.png" sizes="180x180" />
            <link rel="shortcut icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
            <link rel="shortcut icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" color="#5bbad5" href="/safari-pinned-tab.svg" />
            {faviconUrl && (
                <>
                    <link rel="shortcut icon" href={faviconUrl} />
                    <link rel="apple-touch-icon" href={faviconUrl} />
                    <meta name="msapplication-TileImage" content={faviconUrl} />
                    <meta
                        name="msapplication-TileColor"
                        content={themeSettings.headerBackgroundColor}
                    />
                    <meta name="theme-color" content={themeSettings.headerBackgroundColor} />
                </>
            )}
        </Head>
    );
}
export default Branding;
