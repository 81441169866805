import { useCurrentLocale } from '@prezly/theme-kit-nextjs';

import { useFooterLinksTranslations } from '../libs/hooks';

import styles from './Footer.module.scss';

function Footer() {
    const currentLocale = useCurrentLocale();

    const footerLinks = useFooterLinksTranslations(currentLocale);

    return (
        <div className={styles.container}>
            {footerLinks.map((link) => (
                <a href={link.href} key={link.title} className={styles.link}>
                    {link.title}
                </a>
            ))}
            <span className={styles.copy}>&copy; {new Date().getFullYear()} Roompot</span>
        </div>
    );
}

export default Footer;
