import { useCompanyInformation } from '@prezly/theme-kit-nextjs';

import ExtraFooter from '../ExtraFooter/ExtraFooter';

import { hasAnyAboutInformation, hasAnyContactInformation } from './utils';

import styles from './Boilerplate.module.scss';

function Boilerplate() {
    const companyInformation = useCompanyInformation();

    const hasAboutInformation = hasAnyAboutInformation(companyInformation);
    const hasContactInformation = hasAnyContactInformation(companyInformation);

    if (!hasAboutInformation && !hasContactInformation) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className="container">
                <ExtraFooter />
            </div>
        </div>
    );
}

export default Boilerplate;
