/* eslint-disable @next/next/no-img-element */
import { useCurrentLocale } from '@prezly/theme-kit-nextjs';

import { useFooterPaymentsInformation } from '../libs/hooks';

import styles from './Payments.module.scss';

function Payments() {
    const currentLocale = useCurrentLocale();
    const locale = currentLocale.toUnderscoreCode();
    const info = useFooterPaymentsInformation(currentLocale);

    return (
        <div className={styles.box}>
            <h3>{info?.title}</h3>
            <ul className={styles.paymentbox}>
                {(locale === 'nl_NL' || locale === 'nl_BE') && (
                    <li className={styles.paymentIdeal}>
                        <img
                            data-src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-ideal.svg"
                            src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-ideal.svg"
                            alt="Ideal"
                        />
                    </li>
                )}
                <li className={styles.paymentVisa}>
                    <img
                        data-src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-visa.svg"
                        src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-visa.svg"
                        alt="Visa"
                    />
                </li>
                <li className={styles.paymentMastercard}>
                    <img
                        data-src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-mastercard.svg"
                        src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-mastercard.svg"
                        alt="Mastercard"
                    />
                </li>
                <li className={styles.paymentAmericanExpress}>
                    <img
                        data-src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-ae.svg"
                        src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-ae.svg"
                        alt="American Express"
                    />
                </li>
                {locale !== 'en_GB' && (
                    <li className={styles.paymentSofort}>
                        <img
                            data-src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-sofort.svg"
                            src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-sofort.svg"
                            alt="Klarna"
                        />
                    </li>
                )}
                {locale !== 'en_GB' && locale !== 'de' && (
                    <li className={styles.paymentMrcash}>
                        <img
                            data-src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-bancontact.svg"
                            src="https://cdn.roompot.com/Static/images/roompot/sprites/svg/payment-bancontact.svg"
                            alt="Bancontact"
                        />
                    </li>
                )}
            </ul>
        </div>
    );
}

export default Payments;
