import { useCurrentLocale } from '@prezly/theme-kit-nextjs';
import { Tooltip } from 'react-tippy';

import { useFooterNotificationLinks } from '../libs/hooks';
import type { TooltipProps } from '../libs/translations/social';

import styles from './FooterNotification.module.scss';

function FooterNotification() {
    const locale = useCurrentLocale();
    const list: TooltipProps[] = useFooterNotificationLinks(locale);

    return (
        <div className={styles.container}>
            <div className={styles.contentBox}>
                <ul>
                    {list.map(({ title, tooltip }, index) => (
                        <li key={index}>
                            {/* @ts-ignore */}
                            <Tooltip
                                html={
                                    <div className={styles.tooltipContainer}>
                                        <p className={styles.tooltipBody}>{tooltip}</p>
                                    </div>
                                }
                                position="top"
                                trigger="click"
                                hideOnClick={true}
                                sticky={true}
                                distance={25}
                            >
                                <h3>{title}</h3>
                            </Tooltip>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default FooterNotification;
