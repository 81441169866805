/* eslint-disable @typescript-eslint/naming-convention */

import type { TooltipProps } from './social';

export interface NotificationsTranslationsProps<T> {
    ['notifications']: {
        [key: string]: T[];
    };
}

const translations: NotificationsTranslationsProps<TooltipProps> = {
    notifications: {
        en: [
            {
                title: 'Available daily',
                tooltip:
                    'Our Guest Service is at your service 7 days a week. Today between 09.00 and 20.30',
            },
            {
                title: 'Flexible payment',
                tooltip:
                    ' With Roompot you can always pay in instalments at no extra fee. The first instalment must be paid within 15 days after you make your reservation. The total amount is payable 4 weeks before arrival',
            },
            {
                title: 'Free changes',
                tooltip:
                    'Do you want to book extras, book for more people or do you want to change the period of your stay or your accommodation? Roompot allows you to change your booking up to 28 days before your arrival date, at no extra cost. Our Customer service will gladly help you to change your booking',
            },
        ],
        fr_FR: [
            {
                title: 'Accessible tous les jours',
                tooltip:
                    "Notre service clientèle est à votre service tous les jours. Aujourd'hui entre 09.00 - 20.30 heures",
            },
            {
                title: 'Paiement flexible',
                tooltip:
                    'Chez Roompot, vous pouvez toujours payer en plusieurs fois sans frais. Le premier versement doit être effectué dans les 15 jours suivant la réservation. Le montant total doit être payé 4 semaines avant votre arrivée',
            },
            {
                title: 'Modifier gratuitement',
                tooltip:
                    "Voulez-vous réserver des suppléments, élargir votre groupe de voyage ou changer la période ou le séjour ? Chez Roompot, c'est possible jusqu'à 28 jours avant votre date d'arrivée sans frais supplémentaires. Le service client sera heureux de vous aider à modifier votre réservation",
            },
        ],
        fr_BE: [
            {
                title: 'Accessible tous les jours',
                tooltip:
                    "Notre service clientèle est à votre service tous les jours. Aujourd'hui entre 09.00 - 20.30 heures",
            },
            {
                title: 'Paiement flexible',
                tooltip:
                    'Chez Roompot, vous pouvez toujours payer en plusieurs fois sans frais. Le premier versement doit être effectué dans les 15 jours suivant la réservation. Le montant total doit être payé 4 semaines avant votre arrivée',
            },
            {
                title: 'Modifier gratuitement',
                tooltip:
                    "Voulez-vous réserver des suppléments, élargir votre groupe de voyage ou changer la période ou le séjour ? Chez Roompot, c'est possible jusqu'à 28 jours avant votre date d'arrivée sans frais supplémentaires. Le service client sera heureux de vous aider à modifier votre réservation",
            },
        ],
        nl_BE: [
            {
                title: 'Dagelijks bereikbaar',
                tooltip:
                    'Onze Gastenservice staat 7 dagen per week voor je klaar. Vandaag zijn we bereikbaar tussen 09.00-20.30 uur',
            },
            {
                title: 'Flexibel betalen',
                tooltip:
                    'Bij Roompot kun je altijd kosteloos in termijnen betalen. De eerste termijn dien je binnen 15 dagen na het boeken te betalen. Het totaalbedrag moet 4 weken voor aankomst betaald zijn',
            },
            {
                title: 'Gratis wijzigen',
                tooltip:
                    'Wil je extra’s bijboeken, je reisgezelschap uitbreiden of wil je wijzigen van periode of verblijf? Bij Roompot kan dit tot 28 dagen voor je aankomstdatum en zonder extra kosten. De Gastenservice helpt je graag bij het wijzigen van je boeking',
            },
        ],
        nl_NL: [
            {
                title: 'Dagelijks bereikbaar',
                tooltip:
                    'Onze Gastenservice staat 7 dagen per week voor je klaar. Vandaag zijn we bereikbaar tussen 09.00-20.30 uur',
            },
            {
                title: 'Flexibel betalen',
                tooltip:
                    'Bij Roompot kun je altijd kosteloos in termijnen betalen. De eerste termijn dien je binnen 15 dagen na het boeken te betalen. Het totaalbedrag moet 4 weken voor aankomst betaald zijn',
            },
            {
                title: 'Gratis wijzigen',
                tooltip:
                    'Wil je extra’s bijboeken, je reisgezelschap uitbreiden of wil je wijzigen van periode of verblijf? Bij Roompot kan dit tot 28 dagen voor je aankomstdatum en zonder extra kosten. De Gastenservice helpt je graag bij het wijzigen van je boeking',
            },
        ],
        de: [
            {
                title: 'Täglich erreichbar',
                tooltip:
                    'Unser Gästeservice ist 7 Tage die Woche für dich da und hilft dir gern weiter. Heute zwischen 09.00-20.30 Uhr',
            },
            {
                title: 'Flexibel bezahlen',
                tooltip:
                    'Bei Roompot kannst du jederzeit ohne Aufpreis in Raten bezahlen. Die erste Rate muss innerhalb von 15 Tagen nach der Buchung bezahlt werden. Der Gesamtbetrag muss 4 Wochen vor Anreise bezahlt werden',
            },
            {
                title: 'Kostenlos umbuchen',
                tooltip:
                    'Möchtest du Extras buchen, deine Reisegruppe erweitern oder den Zeitraum oder die Unterkunft ändern? Bei Roompot ist dies bis zu 28 Tage vor deiner Ankunft und ohne zusätzliche Kosten möglich. Der Gästeservice hilft dir gerne bei der Änderung deiner Buchung',
            },
        ],
    },
};

export default translations;
