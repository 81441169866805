/* eslint-disable @typescript-eslint/naming-convention */
import type { ExtraLinkProps } from '../../../Header/lib/translations';

export interface SeoTranslationsProps<T> {
    ['seo']: {
        [key: string]: { title: string; links: T[] }[];
    };
}

const translations: SeoTranslationsProps<ExtraLinkProps> = {
    seo: {
        de: [
            {
                title: 'Ferienparks',
                links: [
                    { href: 'https://www.roompot.de/reiseziele/', title: 'Ferienparks' },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/',
                        title: 'Ferienparks Niederlande',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/belgien/',
                        title: 'Ferienparks Belgien',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/deutschland/',
                        title: 'Ferienparks Deutschland',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/frankreich/',
                        title: 'Ferienparks Frankreich',
                    },
                ],
            },
            {
                title: 'Ferienparks Niederlande',
                links: [
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/drenthe/',
                        title: 'Ferienparks Drenthe',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/friesland/',
                        title: 'Ferienparks Friesland',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/gelderland/',
                        title: 'Ferienparks Gelderland',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/groningen/',
                        title: 'Ferienparks Groningen',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/limburg-nl/',
                        title: 'Ferienparks Limburg',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/noord-brabant/',
                        title: 'Ferienparks Noord-Brabant',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/noord-holland/',
                        title: 'Ferienparks Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/overijssel/',
                        title: 'Ferienparks Overijssel',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/zeeland/',
                        title: 'Ferienparks Zeeland',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/zuid-holland/',
                        title: 'Ferienparks Zuid-Holland',
                    },
                ],
            },
            {
                title: 'Orte/Regionen',
                links: [
                    {
                        href: 'https://www.roompot.de/angebote/breskens/ferienpark/',
                        title: 'Ferienparks Breskens',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/cadzand-bad/ferienpark/',
                        title: 'Ferienparks Cadzand-Bad',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/domburg/ferienpark/',
                        title: 'Ferienparks Domburg',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/dishoek/ferienpark/',
                        title: 'Ferienparks Dishoek',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/den-haag/ferienpark/',
                        title: 'Ferienparks Den Haag',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/kamperland/ferienpark/',
                        title: 'Ferienparks Kamperland',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/maastricht/ferienpark/',
                        title: 'Ferienparks Maastricht',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/nieuwvliet-bad/ferienpark/',
                        title: 'Ferienparks Nieuwvliet-Bad',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/ouddorp/ferienpark/',
                        title: 'Ferienparks Ouddorp',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/renesse/ferienpark/',
                        title: 'Ferienparks Renesse',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/scheveningen/ferienpark/',
                        title: 'Ferienparks Scheveningen',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/valkenburg/ferienpark/',
                        title: 'Ferienparks Valkenburg',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/veluwe/',
                        title: 'Ferienparks Veluwe',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/nordseeinseln/',
                        title: 'Ferienparks Nordseeinseln',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/walcheren/ferienpark/',
                        title: 'Ferienparks Walcheren',
                    },
                ],
            },
            {
                title: 'Campings',
                links: [
                    { href: 'https://www.roompot.de/campings/', title: 'Campings' },
                    {
                        href: 'https://www.roompot.de/campingplatze/niederlande/',
                        title: 'Campings Niederlande',
                    },
                ],
            },
            {
                title: 'Campings Niederlande',
                links: [
                    {
                        href: 'https://www.roompot.de/campingplatze/niederlande/drenthe/',
                        title: 'Campings Drenthe',
                    },
                    {
                        href: 'https://www.roompot.de/campingplatze/niederlande/limburg-nl/',
                        title: 'Campings Limburg',
                    },
                    {
                        href: 'https://www.roompot.de/campingplatze/niederlande/noord-brabant/',
                        title: 'Campings Noord-Brabant',
                    },
                    {
                        href: 'https://www.roompot.de/campingplatze/niederlande/noord-holland/',
                        title: 'Campings Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.de/campingplatze/niederlande/zeeland/',
                        title: 'Campings Zeeland',
                    },
                    {
                        href: 'https://www.roompot.de/campingplatze/niederlande/zuid-holland/',
                        title: 'Campings Zuid-Holland',
                    },
                ],
            },
            {
                title: 'Campings Zeeland',
                links: [
                    {
                        href: 'https://www.roompot.de/angebote/dishoek/camping/',
                        title: 'Campings Dishoek',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/domburg/camping/',
                        title: 'Campings Domburg',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/walcheren/camping/',
                        title: 'Campings Walcheren',
                    },
                ],
            },
            {
                title: 'Mobilheim',
                links: [
                    { href: 'https://www.roompot.de/ferienparks/mobilheim/', title: 'Mobilheim' },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/mobilheim/',
                        title: 'Mobilheim Niederlande',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/zeeland/mobilheim/',
                        title: 'Mobilheim Zeeland',
                    },
                ],
            },
            {
                title: 'Chalets',
                links: [
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/chalet/',
                        title: 'Chalets Niederlande',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/friesland/chalet/',
                        title: 'Chalets Friesland',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/noord-holland/chalet/',
                        title: 'Chalets Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/veluwe/chalet/',
                        title: 'Chalets Veluwe',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/niederlande/zeeland/chalet/',
                        title: 'Chalets Zeeland',
                    },
                    {
                        href: 'https://www.roompot.de/ferienparks/frankreich/chalet/',
                        title: 'Chalets Frankreich',
                    },
                ],
            },
        ],
        nl_NL: [
            {
                title: 'Vakantieparken',
                links: [
                    { href: 'https://www.roompot.nl/vakantieparken/', title: 'Vakantieparken' },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/',
                        title: 'Vakantieparken Nederland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/drenthe/',
                        title: 'Vakantieparken Drenthe',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/friesland/',
                        title: 'Vakantieparken Friesland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/gelderland/',
                        title: 'Vakantieparken Gelderland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/groningen/',
                        title: 'Vakantieparken Groningen',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/limburg-nl/',
                        title: 'Vakantieparken Limburg',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/noord-brabant/',
                        title: 'Vakantieparken Noord-Brabant',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/noord-holland/',
                        title: 'Vakantieparken Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/overijssel/',
                        title: 'Vakantieparken Overijssel',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zeeland/',
                        title: 'Vakantieparken Zeeland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zuid-holland/',
                        title: 'Vakantieparken Zuid-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/waddeneilanden/',
                        title: 'Vakantieparken Waddeneilanden',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/belgie/',
                        title: 'Vakantieparken België',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/duitsland/',
                        title: 'Vakantieparken Duitsland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/frankrijk/',
                        title: 'Vakantieparken Frankrijk',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/italie/',
                        title: 'Vakantieparken Italië',
                    },
                    {
                        href: 'https://www.roompot.nl/bestemmingen/kroatie/',
                        title: 'Vakantieparken Kroatië',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/luxemburg/',
                        title: 'Vakantieparken Luxemburg',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/spanje/',
                        title: 'Vakantieparken Spanje',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/strand/',
                        title: 'Vakantieparken aan het strand',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/kust/',
                        title: 'Vakantieparken aan de kust',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/vakantieparken-aan-een-meer/',
                        title: 'Vakantieparken aan een meer',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/bos/',
                        title: 'Vakantieparken in het bos',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/huisdiervriendelijk/',
                        title: 'Huisdiervriendelijke vakantieparken',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/duurzame-parken/',
                        title: 'Duurzame vakantieparken',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/kindvriendelijk/',
                        title: 'Kindvriendelijke vakantieparken',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/luxe/',
                        title: 'Luxe vakantieparken',
                    },
                ],
            },
            {
                title: 'Beach House',
                links: [
                    {
                        href: 'https://www.roompot.nl/vakantieparken/beach-house/',
                        title: 'Beach House',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/beach-house/',
                        title: 'Beach House Nederland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/noord-holland/beach-house/',
                        title: 'Beach House Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zeeland/beach-house/',
                        title: 'Beach House Zeeland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zuid-holland/beach-house/',
                        title: 'Beach House Zuid-Holland',
                    },
                ],
            },
            {
                title: 'Villa',
                links: [
                    { href: 'https://www.roompot.nl/vakantieparken/villa/', title: 'Villa' },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/kust/villa/',
                        title: 'Villa aan de kust',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/villa/',
                        title: 'Villa Nederland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/drenthe/villa/',
                        title: 'Villa Drenthe',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/limburg-nl/villa/',
                        title: 'Villa Limburg',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/noord-brabant/villa/',
                        title: 'Villa Noord-Brabant',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/noord-holland/villa/',
                        title: 'Villa Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/overijssel/villa/',
                        title: 'Villa Overijssel',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zeeland/villa/',
                        title: 'Villa Zeeland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zuid-holland/villa/',
                        title: 'Villa Zuid-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/belgie/villa/',
                        title: 'Villa België',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/duitsland/villa/',
                        title: 'Villa Duitsland',
                    },
                ],
            },
            {
                title: 'Lodge',
                links: [
                    { href: 'https://www.roompot.nl/vakantieparken/lodge/', title: 'Lodge' },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/lodge/',
                        title: 'Lodge Nederland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/ameland/lodge/',
                        title: 'Lodge Ameland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/drenthe/lodge/',
                        title: 'Lodge Drenthe',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/friesland/lodge/',
                        title: 'Lodge Friesland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/noord-holland/lodge/',
                        title: 'Lodge Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/noord-brabant/lodge/',
                        title: 'Lodge Noord-Brabant',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/overijssel/lodge/',
                        title: 'Lodge Overijssel',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zeeland/lodge/',
                        title: 'Lodge Zeeland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zuid-holland/lodge/',
                        title: 'Lodge Zuid-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/waddeneilanden/lodge/',
                        title: 'Lodge Waddeneilanden',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/belgie/lodge/',
                        title: 'Lodge België',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/lodge-west-vlaanderen/',
                        title: 'Lodge West-Vlaanderen',
                    },
                ],
            },
            {
                title: 'Chalet',
                links: [
                    { href: 'https://www.roompot.nl/vakantieparken/chalet/', title: 'Chalet' },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/chalet/',
                        title: 'Chalet Nederland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/friesland/chalet/',
                        title: 'Chalet Friesland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/noord-holland/chalet/',
                        title: 'Chalet Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/veluwe/chalet/',
                        title: 'Chalet Veluwe',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zeeland/chalet/',
                        title: 'Chalet Zeeland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/frankrijk/chalet/',
                        title: 'Chalet Frankrijk',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/spanje/chalet/',
                        title: 'Chalet Spanje',
                    },
                ],
            },
            {
                title: 'Verblijfsduur',
                links: [
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/weekendje-weg/',
                        title: 'Weekendje weg',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/midweek-weg/',
                        title: 'Midweek weg',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/week-weg/',
                        title: 'Weekje weg',
                    },
                ],
            },
            {
                title: 'Campings',
                links: [
                    { href: 'https://www.roompot.nl/campings/', title: 'Campings' },
                    {
                        href: 'https://www.roompot.nl/campings/nederland/',
                        title: 'Campings Nederland',
                    },
                    {
                        href: 'https://www.roompot.nl/campings/nederland/drenthe/',
                        title: 'Campings Drenthe',
                    },
                    {
                        href: 'https://www.roompot.nl/campings/nederland/limburg-nl/',
                        title: 'Campings Limburg',
                    },
                    {
                        href: 'https://www.roompot.nl/campings/nederland/noord-brabant/',
                        title: 'Campings Noord-Brabant',
                    },
                    {
                        href: 'https://www.roompot.nl/campings/nederland/noord-holland/',
                        title: 'Campings Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/campings/nederland/zeeland/',
                        title: 'Campings Zeeland',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/dishoek/',
                        title: 'Campings Dishoek',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/domburg/',
                        title: 'Campings Domburg',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/walcheren/',
                        title: 'Campings Walcheren',
                    },
                    {
                        href: 'https://www.roompot.nl/campings/nederland/zuid-holland/',
                        title: 'Campings Zuid-Holland',
                    },
                ],
            },
        ],
        nl_BE: [
            {
                title: 'Vakantieparken',
                links: [
                    { href: 'https://www.roompot.nl/vakantieparken/', title: 'Vakantieparken' },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/',
                        title: 'Vakantieparken Nederland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/drenthe/',
                        title: 'Vakantieparken Drenthe',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/friesland/',
                        title: 'Vakantieparken Friesland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/gelderland/',
                        title: 'Vakantieparken Gelderland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/groningen/',
                        title: 'Vakantieparken Groningen',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/limburg-nl/',
                        title: 'Vakantieparken Limburg',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/noord-brabant/',
                        title: 'Vakantieparken Noord-Brabant',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/noord-holland/',
                        title: 'Vakantieparken Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/overijssel/',
                        title: 'Vakantieparken Overijssel',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zeeland/',
                        title: 'Vakantieparken Zeeland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/zuid-holland/',
                        title: 'Vakantieparken Zuid-Holland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/nederland/waddeneilanden/',
                        title: 'Vakantieparken Waddeneilanden',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/belgie/',
                        title: 'Vakantieparken België',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/duitsland/',
                        title: 'Vakantieparken Duitsland',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/frankrijk/',
                        title: 'Vakantieparken Frankrijk',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/italie/',
                        title: 'Vakantieparken Italië',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/kroatie/',
                        title: 'Vakantieparken Kroatië',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/luxemburg/',
                        title: 'Vakantieparken Luxemburg',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/spanje/',
                        title: 'Vakantieparken Spanje',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/strand/',
                        title: 'Vakantieparken aan het strand',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/kust/',
                        title: 'Vakantieparken aan de kust',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/vakantieparken-aan-een-meer/',
                        title: 'Vakantieparken aan een meer',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/bos/',
                        title: 'Vakantieparken in het bos',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/huisdiervriendelijk/',
                        title: 'Huisdiervriendelijke vakantieparken',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/duurzame-parken/',
                        title: 'Duurzame vakantieparken',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/kindvriendelijk/',
                        title: 'Kindvriendelijke vakantieparken',
                    },
                    {
                        href: 'https://www.roompot.nl/vakantieparken/luxe/',
                        title: 'Luxe vakantieparken',
                    },
                ],
            },
            {
                title: 'Beach House',
                links: [
                    {
                        href: 'https://www.roompot.be/vakantieparken/beach-house/',
                        title: 'Beach House',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/beach-house/',
                        title: 'Beach House Nederland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/noord-holland/beach-house/',
                        title: 'Beach House Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/zeeland/beach-house/',
                        title: 'Beach House Zeeland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/zuid-holland/beach-house/',
                        title: 'Beach House Zuid-Holland',
                    },
                ],
            },
            {
                title: 'Villa',
                links: [
                    { href: 'https://www.roompot.be/vakantieparken/villa/', title: 'Villa' },
                    {
                        href: 'https://www.roompot.be/vakantieparken/kust/villa/',
                        title: 'Villa aan de kust',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/villa/',
                        title: 'Villa Nederland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/drenthe/villa/',
                        title: 'Villa Drenthe',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/limburg-nl/villa/',
                        title: 'Villa Limburg',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/noord-brabant/villa/',
                        title: 'Villa Noord-Brabant',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/noord-holland/villa/',
                        title: 'Villa Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/overijssel/villa/',
                        title: 'Villa Overijssel',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/zeeland/villa/',
                        title: 'Villa Zeeland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/zuid-holland/villa/',
                        title: 'Villa Zuid-Holland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/belgie/villa/',
                        title: 'Villa België',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/duitsland/villa/',
                        title: 'Villa Duitsland',
                    },
                ],
            },
            {
                title: 'Lodge',
                links: [
                    { href: 'https://www.roompot.be/vakantieparken/lodge/', title: 'Lodge' },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/lodge/',
                        title: 'Lodge Nederland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/ameland/lodge/',
                        title: 'Lodge Ameland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/drenthe/lodge/',
                        title: 'Lodge Drenthe',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/friesland/lodge/',
                        title: 'Lodge Friesland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/noord-holland/lodge/',
                        title: 'Lodge Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/noord-brabant/lodge/',
                        title: 'Lodge Noord-Brabant',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/overijssel/lodge/',
                        title: 'Lodge Overijssel',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/zeeland/lodge/',
                        title: 'Lodge Zeeland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/zuid-holland/lodge/',
                        title: 'Lodge Zuid-Holland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/waddeneilanden/lodge/',
                        title: 'Lodge Waddeneilanden',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/belgie/lodge/',
                        title: 'Lodge België',
                    },
                    {
                        href: 'https://www.roompot.be/aanbiedingen/lodge-west-vlaanderen/',
                        title: 'Lodge West-Vlaanderen',
                    },
                ],
            },
            {
                title: 'Chalet',
                links: [
                    { href: 'https://www.roompot.be/vakantieparken/chalet/', title: 'Chalet' },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/chalet/',
                        title: 'Chalet Nederland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/friesland/chalet/',
                        title: 'Chalet Friesland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/noord-holland/chalet/',
                        title: 'Chalet Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/veluwe/chalet/',
                        title: 'Chalet Veluwe',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/nederland/zeeland/chalet/',
                        title: 'Chalet Zeeland',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/frankrijk/chalet/',
                        title: 'Chalet Frankrijk',
                    },
                    {
                        href: 'https://www.roompot.be/vakantieparken/spanje/chalet/',
                        title: 'Chalet Spanje',
                    },
                ],
            },
            {
                title: 'Verblijfsduur',
                links: [
                    {
                        href: 'https://www.roompot.be/aanbiedingen/weekendje-weg/',
                        title: 'Weekendje weg',
                    },
                    {
                        href: 'https://www.roompot.be/aanbiedingen/midweek-weg/',
                        title: 'Midweek weg',
                    },
                    {
                        href: 'https://www.roompot.be/aanbiedingen/week-weg/',
                        title: 'Weekje weg',
                    },
                ],
            },
            {
                title: 'Campings',
                links: [
                    { href: 'https://www.roompot.be/campings/', title: 'Campings' },
                    {
                        href: 'https://www.roompot.be/campings/nederland/',
                        title: 'Campings Nederland',
                    },
                    {
                        href: 'https://www.roompot.be/campings/nederland/drenthe/',
                        title: 'Campings Drenthe',
                    },
                    {
                        href: 'https://www.roompot.be/campings/nederland/limburg-nl/',
                        title: 'Campings Limburg',
                    },
                    {
                        href: 'https://www.roompot.be/campings/nederland/noord-brabant/',
                        title: 'Campings Noord-Brabant',
                    },
                    {
                        href: 'https://www.roompot.be/campings/nederland/noord-holland/',
                        title: 'Campings Noord-Holland',
                    },
                    {
                        href: 'https://www.roompot.be/campings/nederland/zeeland/',
                        title: 'Campings Zeeland',
                    },
                    {
                        href: 'https://www.roompot.be/aanbiedingen/dishoek/',
                        title: 'Campings Dishoek',
                    },
                    {
                        href: 'https://www.roompot.be/aanbiedingen/domburg/',
                        title: 'Campings Domburg',
                    },
                    {
                        href: 'https://www.roompot.be/aanbiedingen/walcheren/',
                        title: 'Campings Walcheren',
                    },
                    {
                        href: 'https://www.roompot.be/campings/nederland/zuid-holland/',
                        title: 'Campings Zuid-Holland',
                    },
                ],
            },
        ],
    },
};

export default translations;
