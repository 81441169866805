import type { LocaleObject } from '@prezly/theme-kit-core';
import { useMemo } from 'react';

import type { ExtraLinkProps } from '../../Header/lib/translations';

import {
    footerLinkTranslations,
    informationTranslations,
    notificationTranslations,
    paymentsTranslations,
    seoTranslations,
    socialTranslations,
} from './translations';
import type { TooltipProps } from './translations/social';

export function useFooterNotificationLinks(locale: LocaleObject) {
    const memoisedLinks = useMemo<TooltipProps[]>(() => {
        const { notifications } = notificationTranslations;
        return notifications[locale.toUnderscoreCode()] ?? notifications.en;
    }, [locale]);

    return memoisedLinks;
}

export function useFooterInformationLinks(locale: LocaleObject) {
    const memoisedLinks = useMemo<{ title: string; links: ExtraLinkProps[] }[]>(() => {
        const { information } = informationTranslations;
        return information[locale.toUnderscoreCode()] ?? information.en;
    }, [locale]);

    return memoisedLinks;
}

export function useFooterSocialInformation(locale: LocaleObject) {
    const memoisedInfo = useMemo<{ title: string } | null>(() => {
        const { social } = socialTranslations;
        return social[locale.toUnderscoreCode()] ?? social.en;
    }, [locale]);

    return memoisedInfo;
}

export function useFooterPaymentsInformation(locale: LocaleObject) {
    const memoisedInfo = useMemo<{ title: string } | null>(() => {
        const { payments } = paymentsTranslations;
        return payments[locale.toUnderscoreCode()] ?? payments.en;
    }, [locale]);

    return memoisedInfo;
}

export function useFooterSeoInformationLinks(locale: LocaleObject) {
    const memoisedInfo = useMemo<{ title: string; links: ExtraLinkProps[] }[]>(() => {
        const { seo } = seoTranslations;
        return seo[locale.toUnderscoreCode()] ?? seo.en;
    }, [locale]);

    return memoisedInfo;
}

export function useFooterLinksTranslations(locale: LocaleObject) {
    const memoisedLinks = useMemo<{ title: string; href: string }[]>(() => {
        const { footerLinks } = footerLinkTranslations;
        return footerLinks[locale.toUnderscoreCode()] ?? footerLinks.en;
    }, [locale]);

    return memoisedLinks;
}
