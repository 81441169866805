import { useCompanyInformation, useCurrentLocale } from '@prezly/theme-kit-nextjs';
import classNames from 'classnames';

import { Dropdown, SocialMedia } from '@/components';

import { hasAnySocialMedia } from '../Boilerplate/utils';
import SubscribeForm from '../SubscribeForm';

import Footer from './Footer';
import { useFooterInformationLinks, useFooterSocialInformation } from './libs/hooks';
import FooterNotification from './Notifications/FooterNotification';
import Payments from './Payments';
import FooterSeo from './Seo/FooterSeo';

import styles from './ExtraFooter.module.scss';

function OriginFooter() {
    const companyInformation = useCompanyInformation();
    const currentLocal = useCurrentLocale();
    const locale = currentLocal.toUnderscoreCode();
    const hasSocialMedia = hasAnySocialMedia(companyInformation);
    const information = useFooterInformationLinks(currentLocal);
    const social = useFooterSocialInformation(currentLocal);

    const shouldShowSubscriptionForm = locale === 'nl_NL' || locale === 'de' || locale === 'nl_BE';

    return (
        <>
            <FooterNotification />
            <div className={styles.container}>
                <div className={styles.contentbox}>
                    <div className={styles.listWrapper}>
                        {information?.map(({ title, links }, index) => (
                            <div className={styles.column} key={index}>
                                <div className={styles.box}>
                                    <h3>{title}</h3>
                                    <ul className={styles.list}>
                                        {links.map((element, linkIndex) => (
                                            <li key={linkIndex}>
                                                <a href={element.href} className={styles.link}>
                                                    {element.title}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.dropdownWrapper}>
                        {information?.map(({ title, links }) => (
                            <Dropdown
                                showCaret
                                withMobileDisplay
                                label={title}
                                buttonClassName={styles.dropdownButton}
                                menuWrapperClassName={styles.dropdownItems}
                                key={title}
                            >
                                {links.map((link) => (
                                    <Dropdown.Item
                                        href={link.href}
                                        key={link.title}
                                        className={styles.dropdown}
                                        linkClassName={styles.link}
                                    >
                                        {link.title}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                        ))}
                    </div>
                    {shouldShowSubscriptionForm && (
                        <div>
                            <div className={styles.box}>
                                <SubscribeForm />
                            </div>
                        </div>
                    )}
                </div>

                <div className={classNames(styles.contentbox, styles.paymentAndSocials)}>
                    <div className={classNames(styles.column, styles.payment)}>
                        <Payments />
                    </div>
                    <hr className={styles.divider} />
                    {hasSocialMedia && (
                        <div className={classNames(styles.column, styles.social)}>
                            <div className={styles.box}>
                                <h3>{social?.title}</h3>
                                <SocialMedia
                                    companyInformation={companyInformation}
                                    className={styles.socialMedia}
                                    iconClassName={styles.icon}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <Footer />
                <div className={styles.contentbox}>
                    <FooterSeo />
                </div>
            </div>
        </>
    );
}

export default OriginFooter;
