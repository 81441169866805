/* eslint-disable @typescript-eslint/naming-convention */
import type { ExtraLinkProps } from '../../../Header/lib/translations';

interface InformationTranslationsProps<T> {
    ['information']: {
        [key: string]: { title: string; links: T[] }[];
    };
}

const translations: InformationTranslationsProps<ExtraLinkProps> = {
    information: {
        en: [
            {
                title: 'Booking information',
                links: [
                    {
                        href: 'https://www.roompot.com/information/booking-information/bookable-extras/',
                        title: 'Bookable Extras',
                    },
                    {
                        href: 'https://www.roompot.com/inspiration/certainties/',
                        title: 'Our certainties',
                    },
                    {
                        href: 'https://www.roompot.com/information/booking-information/arrival-information/',
                        title: 'Arrival information',
                    },
                    {
                        href: 'https://www.roompot.com/information/booking-information/keycard/',
                        title: 'Keycard',
                    },
                    {
                        href: 'https://www.roompot.com/information/booking-information/insurances/',
                        title: 'Insurances',
                    },
                    {
                        href: 'https://www.roompot.com/information/booking-information/conditions/',
                        title: 'Conditions',
                    },
                ],
            },
            {
                title: 'Service',
                links: [
                    {
                        href: 'https://www.roompot.com/information/service/frequently-asked-questions/',
                        title: 'Frequently asked questions',
                    },
                    {
                        href: 'https://www.roompot.com/information/service/',
                        title: 'Contact',
                    },
                    {
                        href: 'https://www.roompotbusiness.com',
                        title: 'Roompot Business',
                    },
                    {
                        href: 'https://www.roompot.com/roompot-partner/',
                        title: 'Team up with Roompot',
                    },
                ],
            },
            {
                title: 'About Roompot',
                links: [
                    {
                        href: 'https://www.roompot.com/information/about-roompot/affiliate/',
                        title: 'Affiliate',
                    },
                    {
                        href: 'https://www.roompot.com/information/about-roompot/about-us/',
                        title: 'About us',
                    },
                    {
                        href: 'https://www.roompot.com/koos/',
                        title: 'Koos',
                    },
                ],
            },
        ],
        de: [
            {
                title: 'Buchungsinformation',
                links: [
                    {
                        href: 'https://www.roompot.de/information/buchungs-information/zusatzlich-buchbare-extras/',
                        title: 'Zusätzlich buchbare Extras',
                    },
                    {
                        href: 'https://www.roompot.de/inspiration/sicherheiten/',
                        title: 'Unsere Sicherheiten',
                    },
                    {
                        href: 'https://www.roompot.de/information/buchungs-information/anreise-information/',
                        title: 'Anreise Information',
                    },
                    {
                        href: 'https://www.roompot.de/information/buchungs-information/keycard/',
                        title: 'Keycard',
                    },
                    {
                        href: 'https://www.roompot.de/information/buchungs-information/versicherungen/',
                        title: 'Versicherungen',
                    },
                    {
                        href: 'https://www.roompot.de/information/buchungs-information/bedingungen/',
                        title: 'Bedingungen',
                    },
                ],
            },
            {
                title: 'Service',
                links: [
                    {
                        href: 'https://shop.roompot.de/produkte/geschenkgutscheine',
                        title: 'Geschenkgutscheine',
                    },
                    {
                        href: 'https://www.roompot.de/information/service/haufig-gestellte-fragen/',
                        title: 'Häufig gestellte Fragen',
                    },
                    {
                        href: 'https://www.roompot.de/information/service/',
                        title: 'Kontakt',
                    },
                    {
                        href: 'https://www.roompot.de/information/deine-eigene-ferienunterkunft/ferienwohnung-kaufen/',
                        title: 'Ferienwohnung kaufen',
                    },
                    {
                        href: 'https://www.roompotrealestate.de/verkauf-eines-ferienhauses',
                        title: 'Ferienwohnung verkaufen',
                    },
                    {
                        href: 'https://www.roompot.de/roompot-partner/',
                        title: 'Partner im Verleih',
                    },
                ],
            },
            {
                title: 'Über Roompot',
                links: [
                    {
                        href: 'https://www.roompot.de/information/uber-roompot/affiliate-programme/',
                        title: 'Affiliate-Programme',
                    },
                    {
                        href: 'https://www.roompot.de/information/uber-roompot/newsletter/',
                        title: 'Newsletter',
                    },
                    {
                        href: 'https://www.roompot.de/information/uber-roompot/uber-uns/',
                        title: 'Über uns',
                    },
                    {
                        href: 'https://www.roompot.de/information/uber-roompot/sponsoring/',
                        title: 'Sponsoring',
                    },
                    {
                        href: 'https://www.roompot.de/koos/',
                        title: 'Koos',
                    },
                    {
                        href: 'https://www.roompot.de/angebote/combos/combo-wellness2/wellness/',
                        title: 'Wellness',
                    },
                ],
            },
        ],
        fr_FR: [
            {
                title: 'Information de réservation',
                links: [
                    {
                        href: 'https://www.roompot.fr/information/informations-de-reservation/extras-supplementaires-a-reserver/',
                        title: 'Extras supplémentaire à réserver',
                    },
                    {
                        href: 'https://www.roompot.fr/inspiration/garanties/',
                        title: 'Les garanties de Roompot',
                    },
                    {
                        href: 'https://www.roompot.fr/information/informations-de-reservation/informations-concernant-larrivee/',
                        title: "Informations concernant l'arrivée",
                    },
                    {
                        href: 'https://www.roompot.fr/information/informations-de-reservation/keycard/',
                        title: 'Keycard',
                    },
                    {
                        href: 'https://www.roompot.fr/information/informations-de-reservation/assurances/',
                        title: 'Assurances',
                    },
                    {
                        href: 'https://www.roompot.fr/information/informations-de-reservation/conditions/',
                        title: 'Conditions',
                    },
                ],
            },
            {
                title: 'Service',
                links: [
                    {
                        href: 'https://www.roompot.fr/information/service/questions-frequemment-posees/',
                        title: 'Questions frequemment posees',
                    },
                    {
                        href: 'https://www.roompot.fr/information/service/',
                        title: 'Contact',
                    },
                    {
                        href: 'https://www.roompot.fr/roompot-partner/',
                        title: 'Partenaire en location',
                    },
                ],
            },
            {
                title: 'A propos de Roompot',
                links: [
                    {
                        href: 'https://www.roompot.fr/information/-propos-de-roompot/affiliation/',
                        title: 'Affiliation',
                    },
                    {
                        href: 'https://www.roompot.fr/information/-propos-de-roompot/a-propos-de-nous/',
                        title: 'À propos de nous',
                    },
                    {
                        href: 'https://www.roompot.fr/koos/',
                        title: 'Koos',
                    },
                ],
            },
        ],
        fr_BE: [
            {
                title: 'Information de réservation',
                links: [
                    {
                        href: 'https://www.roompot.be/fr/information/informations-de-reservation/conditions/',
                        title: 'Conditions',
                    },

                    {
                        href: 'https://www.roompot.be/fr/information/informations-de-reservation/informations-concernant-larrivee/',
                        title: "Informations concernant l'arrivée",
                    },
                ],
            },
            {
                title: 'Service',
                links: [
                    {
                        href: 'https://www.roompot.be/fr/information/service/',
                        title: 'Contact',
                    },
                    {
                        href: 'https://www.roompot.be/fr/information/service/questions-frequemment-posees/',
                        title: 'Questions frequemment posees',
                    },
                ],
            },
            {
                title: 'A propos de Roompot',
                links: [
                    {
                        href: 'https://www.roompot.be/fr/information/-propos-de-roompot/a-propos-de-nous/',
                        title: 'À propos de nous',
                    },
                    {
                        href: 'https://www.roompot.be/fr/information/-propos-de-roompot/affiliation/',
                        title: 'Affiliation',
                    },
                ],
            },
        ],

        nl_NL: [
            {
                title: 'Boekingsinformatie',
                links: [
                    {
                        href: 'https://www.roompot.nl/informatie/boekingsinformatie/bij-te-boeken-extras/',
                        title: "Bij te boeken extra's",
                    },
                    {
                        href: 'https://www.roompot.nl/inspiratie/zekerheden/',
                        title: 'Onze zekerheden',
                    },
                    {
                        href: 'https://www.roompot.nl/informatie/boekingsinformatie/aanreisinformatie/',
                        title: 'Aanreisinformatie',
                    },
                    {
                        href: 'https://www.roompot.nl/informatie/boekingsinformatie/keycard/',
                        title: 'Keycard',
                    },
                    {
                        href: 'https://www.roompot.nl/informatie/boekingsinformatie/verzekeringen/',
                        title: 'Verzekeringen',
                    },
                    {
                        href: 'https://www.roompot.nl/informatie/boekingsinformatie/voorwaarden/',
                        title: 'Voorwaarden',
                    },
                ],
            },
            {
                title: 'Service',
                links: [
                    {
                        href: 'https://shop.roompot.nl/producten/cadeaubonnen',
                        title: 'Cadeaubonnen',
                    },
                    {
                        href: 'https://www.roompot.nl/informatie/service/',
                        title: 'Contact',
                    },
                    {
                        href: 'https://www.roompot.nl/informatie/service/veelgestelde-vragen/',
                        title: 'Veelgestelde vragen',
                    },
                    {
                        href: 'https://www.roompot.nl/informatie/recreatiewoning-kopen/woning-kopen/',
                        title: 'Recreatiewoning kopen',
                    },
                    {
                        href: 'https://www.roompotrealestate.nl/verkopen-van-een-recreatiewoning',
                        title: 'Recreatiewoning verkopen',
                    },
                    {
                        href: 'https://www.roompot.nl/care/',
                        title: 'Roompot Care',
                    },
                    {
                        href: 'https://www.roompot.nl/roompot-partner/',
                        title: 'Partner in verhuur',
                    },
                    {
                        href: 'https://www.roompotbusiness.nl',
                        title: 'Zakelijk huren',
                    },
                ],
            },
            {
                title: 'Over Roompot',
                links: [
                    {
                        href: 'https://www.roompot.nl/informatie/over-roompot/affiliate/',
                        title: 'Affiliate',
                    },
                    {
                        href: 'https://www.roompot.nl/nieuwsbrief/',
                        title: 'Nieuwsbrief',
                    },
                    {
                        href: 'https://www.roompot.nl/informatie/over-roompot/over-ons/',
                        title: 'Over ons',
                    },
                    {
                        href: 'https://www.roompot.nl/duurzaamheid/',
                        title: 'Duurzaamheid',
                    },
                    {
                        href: 'https://www.roompot.nl/informatie/over-roompot/sponsoring/',
                        title: 'Sponsoring',
                    },
                    {
                        href: 'https://www.roompot.nl/koos/',
                        title: 'Koos',
                    },
                    {
                        href: 'https://www.roompot.nl/aanbiedingen/combos/combo-wellness2/wellness/',
                        title: 'Wellness',
                    },
                    {
                        href: 'https://jobs.roompot.nl/',
                        title: 'Werken bij Roompot',
                    },
                    {
                        href: 'https://news.roompot.com/',
                        title: 'Nieuws',
                    },
                ],
            },
        ],
        nl_BE: [
            {
                title: 'Boekingsinformatie',
                links: [
                    {
                        href: 'https://www.roompot.be/informatie/boekingsinformatie/bij-te-boeken-extras/',
                        title: "Bij te boeken extra's",
                    },
                    {
                        href: 'https://www.roompot.be/inspiratie/zekerheden/',
                        title: 'Onze zekerheden',
                    },
                    {
                        href: 'https://www.roompot.be/informatie/boekingsinformatie/aanreisinformatie/',
                        title: 'Aanreisinformatie',
                    },
                    {
                        href: 'https://www.roompot.be/informatie/boekingsinformatie/keycard/',
                        title: 'Keycard',
                    },
                    {
                        href: 'https://www.roompot.be/informatie/boekingsinformatie/verzekeringen/',
                        title: 'Verzekeringen',
                    },
                    {
                        href: 'https://www.roompot.be/informatie/boekingsinformatie/voorwaarden/',
                        title: 'Voorwaarden',
                    },
                ],
            },
            {
                title: 'Service',
                links: [
                    {
                        href: 'https://shop.roompot.nl/producten/cadeaubonnen',
                        title: 'Cadeaubonnen',
                    },
                    {
                        href: 'https://www.roompot.be/informatie/service/',
                        title: 'Contact',
                    },
                    {
                        href: 'https://www.roompot.be/informatie/service/veelgestelde-vragen/',
                        title: 'Veelgestelde vragen',
                    },
                    {
                        href: 'https://www.roompot.be/informatie/recreatiewoning-kopen/woning-kopen/',
                        title: 'Recreatiewoning kopen',
                    },
                    {
                        href: 'https://www.roompotrealestate.nl/verkopen-van-een-recreatiewoning',
                        title: 'Recreatiewoning verkopen',
                    },
                    {
                        href: 'https://www.roompot.be/care/',
                        title: 'Roompot Care',
                    },
                ],
            },
            {
                title: 'Over Roompot',
                links: [
                    {
                        href: 'https://www.roompot.be/informatie/over-roompot/affiliate/',
                        title: 'Affiliate',
                    },
                    {
                        href: 'https://www.roompot.be/nieuwsbrief/',
                        title: 'Nieuwsbrief',
                    },
                    {
                        href: 'https://www.roompot.be/informatie/over-roompot/over-ons/',
                        title: 'Over ons',
                    },
                    {
                        href: 'https://www.roompot.be/duurzaamheid/',
                        title: 'Duurzaamheid',
                    },
                    {
                        href: 'https://www.roompot.be/informatie/over-roompot/sponsoring/',
                        title: 'Sponsoring',
                    },
                    {
                        href: 'https://www.roompot.be/koos/',
                        title: 'Koos',
                    },
                    {
                        href: 'https://www.roompot.be/aanbiedingen/combos/combo-wellness2/wellness/',
                        title: 'Wellness',
                    },
                    {
                        href: 'https://jobs.roompot.nl/',
                        title: 'Werken bij Roompot',
                    },
                    {
                        href: 'https://news.roompot.com/',
                        title: 'Nieuws',
                    },
                ],
            },
        ],
    },
};

export default translations;
