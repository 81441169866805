/* eslint-disable @typescript-eslint/naming-convention */

export interface FooterLinksTranslationProps<T> {
    ['footerLinks']: {
        [key: string]: T[];
    };
}

const translations: FooterLinksTranslationProps<{ title: string; href: string }> = {
    footerLinks: {
        en: [
            {
                title: 'Conditions',
                href: 'https://www.roompot.com/information/booking-information/conditions/',
            },
            {
                title: 'Privacy',
                href: 'https://www.roompot.com/syssiteassets/infoblocks/boekingsinformatie/voorwaarden/privacyverklaring-en.pdf',
            },
            {
                title: 'Cookies',
                href: 'https://www.roompot.com/information/booking-information/conditions/oud-cookie-policy/',
            },
            {
                title: 'Disclaimer',
                href: 'https://www.roompot.com/information/booking-information/conditions/disclaimer/',
            },
        ],
        fr_FR: [
            {
                title: 'Conditions générales',
                href: 'https://www.roompot.fr/information/informations-de-reservation/conditions/',
            },
            {
                title: 'Avertissement',
                href: 'https://www.roompot.fr/information/informations-de-reservation/conditions/avertissement/',
            },
            {
                title: 'Confidentialité',
                href: 'https://www.roompot.fr/syssiteassets/infoblocks/boekingsinformatie/voorwaarden/privacyverklaring-fr.pdf',
            },
            {
                title: 'Politique de cookies',
                href: 'https://www.roompot.fr/information/informations-de-reservation/conditions/politique-de-cookies/',
            },
        ],
        fr_BE: [
            {
                title: 'Conditions générales',
                href: 'https://www.roompot.fr/information/informations-de-reservation/conditions/',
            },
            {
                title: 'Avertissement',
                href: 'https://www.roompot.fr/information/informations-de-reservation/conditions/avertissement/',
            },
            {
                title: 'Confidentialité',
                href: 'https://www.roompot.fr/syssiteassets/infoblocks/boekingsinformatie/voorwaarden/privacyverklaring-fr.pdf',
            },
            {
                title: 'Politique de cookies',
                href: 'https://www.roompot.fr/information/informations-de-reservation/conditions/politique-de-cookies/',
            },
        ],
        de: [
            {
                title: 'AGB',
                href: 'https://www.roompot.de/information/buchungs-information/bedingungen/',
            },
            {
                title: 'Datenschutz',
                href: 'https://www.roompot.de/syssiteassets/infoblocks/boekingsinformatie/voorwaarden/privacyverklaring-de.pdf',
            },
            {
                title: 'Cookie Policy',
                href: 'https://www.roompot.de/information/buchungs-information/bedingungen/cookie-policy/',
            },
            {
                title: 'Disclaimer',
                href: 'https://www.roompot.de/information/buchungs-information/bedingungen/disclaimer/',
            },
            {
                title: 'Sitemap',
                href: 'https://www.roompot.de/information/service/sitemap/',
            },
        ],
        nl_NL: [
            {
                title: 'Algemene voorwaarden',
                href: 'https://www.roompot.nl/informatie/boekingsinformatie/voorwaarden/',
            },
            {
                title: 'Privacy',
                href: 'https://www.roompot.nl/syssiteassets/infoblocks/boekingsinformatie/voorwaarden/privacyverklaring-nl.pdf',
            },
            {
                title: 'Cookies',
                href: 'https://www.roompot.nl/informatie/boekingsinformatie/voorwaarden/cookie-policy/',
            },
            {
                title: 'Disclaimer',
                href: 'https://www.roompot.nl/informatie/boekingsinformatie/voorwaarden/disclaimer/',
            },
            {
                title: 'Sitemap',
                href: 'https://www.roompot.nl/informatie/service/sitemap/',
            },
        ],
        nl_BE: [
            {
                title: 'Algemene voorwaarden',
                href: 'https://www.roompot.nl/informatie/boekingsinformatie/voorwaarden/',
            },
            {
                title: 'Privacy',
                href: 'https://www.roompot.nl/syssiteassets/infoblocks/boekingsinformatie/voorwaarden/privacyverklaring-nl.pdf',
            },
            {
                title: 'Cookies',
                href: 'https://www.roompot.nl/informatie/boekingsinformatie/voorwaarden/cookie-policy/',
            },
            {
                title: 'Disclaimer',
                href: 'https://www.roompot.nl/informatie/boekingsinformatie/voorwaarden/disclaimer/',
            },
            {
                title: 'Sitemap',
                href: 'https://www.roompot.nl/informatie/service/sitemap/',
            },
        ],
    },
};

export default translations;
