import type { LocaleObject } from '@prezly/theme-kit-core';
import { useEffect, useState } from 'react';

import type { ExtraLinkProps } from './translations';
import { translations } from './translations';

export function useOriginExtraNavigationLinks(locale: LocaleObject) {
    const [links, setLinks] = useState<ExtraLinkProps[]>([]);

    useEffect(() => {
        const originExtras: { [key: string]: ExtraLinkProps[] } = translations;
        setLinks(originExtras[locale.toUnderscoreCode()] ?? originExtras.en);
    }, [locale]);

    return links;
}
